<template>
    <div
        class="d-flex flex-column"
        :class="gardenPaddings"
        style="gap: 48px;"
        :style="{'background': isMobile ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 64.3%, #F3F3F3 99.94%)' : ''}"
    >
        <span v-if="title" class="font f-w-700 f-s-18 l-h-28 text-center" style="white-space: pre-line;">{{ title }}</span>
        <VueSlickCarousel
            :arrows="false"
            :slides-to-show="deviceSlideRange"
            :autoplay="true"
            :speed="1500"
            :autoplay-speed="400"
        >
            <div v-for="(brandsBundle, i) in getChunkedBrands" :key="i">
                <img
                    class="brands-item d-block m-r-small-20"
                    width="140"
                    height="70"
                    v-for="(brand, m) in brandsBundle"
                    :key="m"
                    :src="$image(`pages/home/brands2024/${brand}`)"
                >
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import chunk from 'lodash/chunk';
import screen from '@/plugins/screen';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
    name: 'LogoGarden',
    components: {
        VueSlickCarousel,
    },
    mixins: [screen],
    props: {
        title: {
            type: String,
            default: '',
        },
        gardenPaddings: {
            type: String,
            default: 'p-x-56 p-y-96 p-x-small-16 p-y-small-48',
        },
    },
    data() {
        return {
            brands: [
                '1.png',
                '2.png',
                '3.png',
                '4.png',
                '5.png',
                '6.png',
                '7.png',
                '8.png',
                '9.png',
                '10.png',
                '11.png',
                '12.png',
                '13.png',
                '14.png',
                '15.png',
                '16.png',
                '17.png',
                '18.png',
                '19.png',
                '20.png',
                '21.png',
                '22.png',
                '23.png',
                '24.png',
                '25.png',
                '26.png',
                '27.png',
                '28.png',
                '29.png',
                '30.png',
                '31.png',
                '32.png',
                '33.png',
                '34.png',
                '35.png',
                '36.png',
                '37.png',
                '38.png',
                '39.png',
                '40.png',
            ],
        };
    },
    computed: {
        getChunkedBrands() {
            return chunk(this.brands, 2);
        },
        deviceSlideRange() {
            return this.isMobile ? 2 : 5;
        },
    },
};
</script>
