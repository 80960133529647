<template>
    <div
        v-if="hasValue"
        class="d-flex p-y-48 p-x-48 p-x-small-16 p-y-small-40"
        style="background-color: #F4F4F4; gap: 40px;"
        :style="{ 'border-radius': isMobile ? '0px' : '60px'}"
        :class="{'align-items-center flex-column flex-lg-row': hasOneStory, 'flex-column': !hasOneStory}"
    >
        <div
            class="d-flex heading"
            :class="{'w-100 justify-content-between': !hasOneStory}"
            :style="{'flex-direction': hasOneStory ? 'column' : 'row'}"
        >
            <span class="font Roboto f-w-700 f-s-36 f-s-small-32 l-h-48">Our Customers Stories</span>
            <nuxt-link
                to="/case-studies"
                :class="{'m-t-40 m-t-small-8': hasOneStory}"
                class="btn md font f-s-16 l-h-24 f-w-800 l-t-s-3 d-inline-flex justify-content-start plain text-start fit-content p-0"
            >
                <!-- :class="item.isSpecialButton ? 'black' : 'plain p-0'" -->
                <span>See all stories</span>
                <span class="icon arrow-right size sm m-l-4" />
            </nuxt-link>
        </div>

        <div v-if="hasOneStory" class="story w-100">
            <div class="d-flex flex-column flex-md-row p-b-small-16" style="background-color: white; border-radius: 16px; border: 1px solid #FAFAFA;">
                <img :src="$image('stories/Image-3.png')" alt="" class="m-b-small-16 aspect">
                <div class="d-flex flex-column p-x-24 p-t-24">
                    <span class="font f-w-800 f-s-24 l-h-32 l-t-s-75" style="color: #141414;">Abe’s Muffin increased retail velocity at Sprouts while generated <span style="color: #0E47C0;">over 40%</span> Repurchase Rate</span>
                    <nuxt-link
                        to="/case-studies"
                        class="btn md font f-s-16 l-h-20 f-w-700 l-t-s-3 d-inline-flex justify-content-start plain m-t-30 m-t-small-32 text-start p-0 fit-content"
                    >
                        <!-- :class="item.isSpecialButton ? 'black' : 'plain p-0'" -->
                        <span>Read their story</span>
                        <span class="icon arrow-right size sm m-l-4" />
                    </nuxt-link>
                </div>
            </div>
        </div>
        <div v-else class="stories">
            <CustomerStoriesItem
                class="story"
                v-for="story in stories"
                :key="story.title"
                :story="story"
            />
        </div>
    </div>
</template>

<script>
import screen from '@/plugins/screen';
import CustomerStoriesItem from './CustomerStoriesItem.vue';

export default {
    name: 'CustomerStories',
    components: {
        CustomerStoriesItem,
    },
    mixins: [screen],
    props: {
        stories: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        hasValue() {
            return this.stories.length > 0;
        },
        hasOneStory() {
            return this.stories.length === 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.stories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
}

@media (max-width: 768px) {
    .stories {
        grid-template-columns: 100%;
    }
}
.story{
    .aspect{
        // width: 385px;
        height: 385px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

    }
    @media screen and (min-width: 768px){
        .aspect{
            width: 238px;
            height: auto;
            border-bottom-left-radius: 8px;
            border-top-right-radius: unset;
        }
        }
}
.heading{
    width: 100%;
    @media screen and (min-width: 768px){
        width: 56%;
        }

}
</style>
